import { render, staticRenderFns } from "./Roulet.vue?vue&type=template&id=0ae1b542&scoped=true&"
import script from "./Roulet.vue?vue&type=script&lang=js&"
export * from "./Roulet.vue?vue&type=script&lang=js&"
import style0 from "./Roulet.vue?vue&type=style&index=0&id=0ae1b542&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae1b542",
  null
  
)

export default component.exports